import React, { useEffect, createRef, useState } from "react";
import { useAppContext } from "./Context";
// import { TweenMax } from "gsap";
import MenuOverlay from "./MenuOverlay";

// let prevOffset = 0;
// let scrollPos = 0;
export default function Navbar() {
  const {
    toggleShowCart,
    toggleShowMenu,
    state: {
      cart: { items },
    },
  } = useAppContext();
  // const [showNavBar, setShowNavBar] = useState(true);
  const [showMenu, setShowMenu] = useState(false);
  const navRef = createRef(null);
  const qtyRef = createRef(null);
  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => document.removeEventListener("scroll", handleScroll);
  }, []);
  // useEffect(() => {
  //   TweenMax.to(navRef.current, {
  //     opacity: showNavBar ? 1 : 0,
  //     duration: 0.3,
  //     y: showNavBar ? 0 : -50,
  //   });
  // }, [showNavBar]);
  // useEffect(() => {
  //   if (qtyRef.current)
  //     TweenMax.fromTo(
  //       qtyRef.current,
  //       { boxShadow: "0 0 0 0 #000" },
  //       { duration: 0.5, boxShadow: "0 0 0 2rem transparent" }
  //     );
  // }, [items]);
  const handleScroll = () => {
    // scrollPos = window.pageYOffset - prevOffset;
    // prevOffset = window.pageYOffset;
    // setShowNavBar(scrollPos <= 0);
  };
  const onClose = () => {
    setShowMenu(!showMenu);
    toggleShowMenu(!showMenu);
  };
  return (
    <>
      <div className="navbar" ref={navRef}>
        <span
          className="cart-btn"
          style={showMenu ? { color: "#fff" } : {}}
          onClick={() => {
            setShowMenu(!showMenu);
            toggleShowMenu(!showMenu);
          }}
        >
          {showMenu ? "BACK" : "MENU"}
        </span>
        <img
          src={require("../Images/mainlogo.png")}
          alt="logo"
          className="logo-adj"
        />
        <span className="cart-btn" onClick={() => toggleShowCart(true)}>
          {items.length > 0 && <span ref={qtyRef}>{items.length}</span>}
          CART
        </span>
      </div>
      {showMenu && <MenuOverlay onClose={onClose} />}
    </>
  );
}
