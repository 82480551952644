import React, { useState } from "react";
// import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import CustomDropdown from "../../Component/CustomDropdown";
import { useAppContext } from "../../Component/Context";
import { StylesProvider } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Carousel from "react-material-ui-carousel";
import { Paper } from "@material-ui/core";

export default function BrandRow({
  name = ["AB", "CD"],
  id,
  detailsJP,
  detailsEng,
  price,
  brandImg,
  detailsImg,
  index = 0,
  sideHighlight,
  accentColors = ["#fff", "#707f8f", "#00a9b1", "#fff"],
  content,
  soon = false,
  contImg,
}) {
  const { addItem } = useAppContext();
  const [itemState, setItemState] = useState({
    name,
    id,
    itemImg: brandImg,
    price,
    qty: 1,
    size: "S",
  });

  const onClick = () => {
    addItem({
      itemId: parseInt(Math.random() * 10000),
      ...itemState,
    });
    toast.success(
      `${itemState.qty} ${itemState.name[1]} ${itemState.name[0]} はカートに追加されました。`,
      {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  };

  function Example(props) {
    var items = [
      {
        image: require("../../Images/" + brandImg[0]),
        color: accentColors[4],
      },
      {
        image: require("../../Images/" + brandImg[1]),
        color: accentColors[4],
      },
      {
        image: require("../../Images/" + brandImg[2]),
        color: "#e6e2e0",
      },
      {
        image: require("../../Images/" + brandImg[3]),
        color: "#e6e2e0",
      },
      {
        image: require("../../Images/" + brandImg[4]),
        color: "#e6e2e0",
      },
    ];

    return (
      <StylesProvider injectFirst>
        <Carousel
          navButtonsAlwaysVisible={true}
          animation="fade"
          timeout={700}
          // style={{ objectFit: "contain" }}
          indicators={false}
          autoPlay={false}
          className="carousel"
        >
          {items.map((item, i) => (
            <Item key={i} item={item} />
          ))}
        </Carousel>
      </StylesProvider>
    );
  }

  function Item(props) {
    return (
      <Paper
        style={{
          // background: props.item.color,
          objectFit: "cover",
        }}
      >
        <img src={props.item.image} alt="dasdasd" className="responsive" />
      </Paper>
    );
  }

  return (
    <div className="brand" id={id}>
      <div
        className="brand-row desk"
        // style={{ backgroundColor: accentColors[4] }}
      >
        {!soon && <Example />}
        {soon && (
          <div className="br-carousel">
            <img
              // style={{ opacity: soon ? 0.3 : 1, border: 0 }}
              src={require(`../../Images/${brandImg}`)}
              alt={detailsImg}
              style={{ verticalAlign: "top" }}
            />

            <div
              className="c-soon"
              style={{
                // background: accentColors[4] + "52",
                position: "absolute",
              }}
            >
              <div style={{ padding: "35% 0", textAlign: "center" }}>
                Coming Soon
              </div>
            </div>
          </div>
        )}

        <div
          className="content"
          style={{
            background: accentColors[1],
            // padding: "5%",
            // paddingRight: "10%",
            // zIndex: 2,
          }}
        >
          {/* {content === true ? (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: name.map(() => "1fr").join(" "),
                fontSize: `20vw`,
              }}
            >
              {name.map((n, ni) => (
                <div>
                  {n.split("").map((s, si) => (
                    <span
                      className={
                        ni === name.length - 1 && si === 0 ? "sel" : ""
                      }
                    >
                      {s}
                    </span>
                  ))}
                </div>
              ))}
            </div>
          ) : (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: name.map(() => "1fr").join(" "),
                fontSize: `18vh`,
              }}
            >
              {name.map((n, ni) => (
                <div>
                  {n.split("").map((s, si) => (
                    <span
                      className={
                        ni === name.length - 1 && si === 0 ? "sel" : ""
                      }
                    >
                      {s}
                    </span>
                  ))}
                </div>
              ))}
            </div>
                    )} */}
          <img
            className="cont-img"
            alt="content"
            src={require(`../../Images/${contImg}`)}
          />

          <p className="rtl" style={{ fontSize: "10px" }}>
            [Item 0{index}]<span>{sideHighlight}</span>
          </p>
        </div>

        {/* <div
          className="br-name"
          style={{ background: accentColors[1], zIndex: 2 }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: name.map(() => "1fr").join(" "),
              fontSize: `${40 / name.length}vw`,
            }}
          >
            {name.map((n, ni) => (
              <div>
                {n.split("").map((s, si) => (
                  <span
                    className={ni === name.length - 1 && si === 0 ? "sel" : ""}
                  >
                    {s}
                  </span>
                ))}
              </div>
            ))}
          </div>
          <p className="rtl">
            [Item 0{index}]<span>{sideHighlight}</span>
          </p>
                </div> */}
      </div>

      <div
        className="brand-row mobrev"
        // style={{ backgroundColor: accentColors[4] }}
      >
        <div
          className="content"
          style={{
            background: accentColors[1],
            // padding: "5%",
            // paddingRight: "10%",
          }}
        >
          <img
            className="cont-img"
            alt="cont"
            src={require(`../../Images/${contImg}`)}
          />

          <p className="rtl">
            [Item 0{index}]<span>{sideHighlight}</span>
          </p>
        </div>
        {!soon && <Example />}
        {soon && (
          <div className="br-carousel" style={{ background: "#ffffff" }}>
            <img
              src={require(`../../Images/${brandImg}`)}
              alt={detailsImg}
              style={{ verticalAlign: "top" }}
            />

            <div
              className="c-soon"
              style={{
                background: accentColors[4] + "52",
                position: "absolute",
              }}
            >
              <div style={{ padding: "25% 0", textAlign: "center" }}>
                Coming Soon
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="brand-row ctt">
        <div
          className="br-details"
          style={{ background: accentColors[2], position: "relative" }}
        >
          <p className="br-details-head">[ Item Details ]</p>
          <h2 style={{ fontFamily: '"Noto Serif JP"' }}>{detailsJP}</h2>
          <p>{detailsEng}</p>
          <div className="br-details-price">
            {price ? (
              `¥${price.toLocaleString()}`
            ) : (
              <div style={{ height: "60px" }}></div>
            )}
          </div>
          <div className="br-details-input">
            <div>
              <p>Size</p>
              {/* <div style={{
                position:"absolute", 
                overflow:"visible", 
                zIndex:"100",
                }}> */}
              <CustomDropdown
                callback={(size) => setItemState((st) => ({ ...st, size }))}
                options={["S", "M", "L", "XL"]}
              />
              {/* </div> */}
            </div>
          <div>
              <p>Quantity</p>
              {/* <div 
              style={{
                position:"absolute", 
                overflow:"visible", 
                zIndex:"100",
                }}> */}
              <CustomDropdown
                // style={{border:"solid", borderColor:"black", padding:"7px"}}
                callback={(qty) => setItemState((st) => ({ ...st, qty }))}
                options={new Array(5).fill("").map((_, i) => i + 1)}
              />
              {/* </div> */}
            </div>
            {/* <div style={{marginTop:"47px"}}> */}
            <button disabled={soon} onClick={onClick}>
              {!!soon ? "Coming Soon" : "Add to Cart"}
            </button>
            {/* </div> */}
          </div>
        </div>

        {id === "1" && <div className="parallax"></div>}
        {id === "2" && <div className="parallax1"></div>}
        {id === "3" && <div className="parallax3"></div>}
        {id === "4" && <div className="parallax4"></div>}
      </div>
    </div>
  );
}
