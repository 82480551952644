var priceObj = {
  japan: {
    1: 700,
    2: 700,
    3: 700,
    4: 700,
  },
  asia: {
    1: 1700,
    2: 1700,
    3: 1700,
    4: 1700,
  },
  europe: {
    1: 2600,
    2: 2600,
    3: 2600,
    4: 2600,
  },
  africa: {
    1: 3100,
    2: 3100,
    3: 3100,
    4: 3100,
  },
};

export default priceObj;
