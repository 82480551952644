import React, { useState, useEffect } from "react";
import { useAppContext } from "./Context";
import OrderService from "../services/Orders.service";
import { PayPalButton } from "react-paypal-button-v2";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import { PAYPAL_CLIENTID } from "../config";

const PayWithPayPal = ({
  ts,
  setCheck,
  name,
  email,
  phone,
  address,
  city,
  state,
  country,
  postCode,
}) => {
  const {
    state: {
      cart: { items },
    },
    emptyCart,
  } = useAppContext();

  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [paidFor, setPaidFor] = useState(false);
  console.log(name, email, phone, address, city, state, country, postCode);

  useEffect(() => {
    const addPayPalScript = async () => {
      const clientId = PAYPAL_CLIENTID;
      console.log(clientId);
      const script = document.createElement("script");
      script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=JPY&disable-funding=credit,card&locale=ja_JP`;
      script.async = true;
      script.addEventListener("load", () => setLoaded(true));
      document.body.appendChild(script);
    };

    if (paidFor) {
      console.log("paid");
    } else if (!paidFor) {
      if (!window.paypal) {
        addPayPalScript();
      } else {
        setLoaded(true);
      }
    }
  }, [paidFor]);

  const errorHandler = (err) => {
    setError(true);
  };

  const successPaymentHandler = async (paymentResult) => {
    console.log(paymentResult);

    setPaidFor(true);

    const res = await OrderService.createOrder(
      paymentResult.id,
      name,
      email,
      phone,
      address,
      city,
      state,
      country,
      postCode,
      ts,
      items
    );
    console.log(res);
    setCheck();
    emptyCart();
  };

  return (
    <div>
      {error && (
        <Alert severity="error">
          The payment can't be processed at the moment.
        </Alert>
      )}
      {!paidFor && loaded ? (
        <PayPalButton
          amount={ts}
          onSuccess={successPaymentHandler}
          onError={errorHandler}
          currency="JPY"
          shippingPreference="NO_SHIPPING"
        />
      ) : (
        <CircularProgress />
      )}
    </div>
  );
};

export default PayWithPayPal;
