import React, { useState } from "react";
import useOutsideClick from "../hooks/useOutsideClick";

export default function DropdownCart({
  options = [],
  callback = () => {},
  value,
}) {
  const [selected, setSelected] = useState(value);
  const [open, setOpen] = useState(false);
  const dref = React.createRef();
  const closeList = () => {
    setOpen(false);
  };

  useOutsideClick(closeList, dref);
  return (
    <div className="c-dd1" ref={dref}>
      <div
        onClick={() => {
          setOpen(!open);
        }}
        className="c-dd1-display"
      >
        <div style={{ fontFamily: "Oswald, sans-serif" }}>数量</div>
        <div style={{ fontFamily: "Oswald, sans-serif" }}>: {selected}</div>
      </div>
      {open && (
        <ul>
          {options.map((o) => (
            <li
              style={{ padding: 0 }}
              onClick={() => {
                closeList();
                setSelected(o);
                callback(o);
              }}
            >
              {o}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
