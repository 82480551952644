import React from "react";
import Cart from "../../Component/Cart";
import Navbar from "../../Component/Navbar";
import BrandRow from "./BrandRow";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { isEmail } from "validator";
import Form from "react-validation/build/form";
import axios from "axios";
import { BASE_URL } from "../../config";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  MuiDialogPaperWidthSm: {
    // overflowY: 'visible'
    maxWidth: "70%",
    borderRadius: "30px",
  },
  dialogContentRoot: {
    overflowY: "visible",
    width: "100%",
  },
  MuiDialogActionsRoot: {
    justifyContent: "center",
  },
});

const items = [
  {
    id: "1",
    name: ["信長", "織田"],
    detailsJP: (
      <div>
        5.6oz ヘヴィウエイトコットン、アメリカの規格で <br />
        5.6オンス以上がヘヴィウエイトコットンとしている、
        <br />
        世界中で一番売れているTシャツ生地を使用。
      </div>
    ),
    detailsEng: (
      <div>
        5.6oz heavyweight cotton, American standard
        <br /> 5.6 ouncesor more is heavy weight cotton,
        <br /> Use the best-selling T-shirt fabric in the world.
      </div>
    ),
    price: 4000,
    brandImg: [
      "Y1-min.png",
      "Y2-min.png",
      "Y3-min.png",
      "Y4-min.png",
      "Y5-min.png",
    ],
    detailsImg: "21(1).png",
    sideHighlight: "Oda Nobunga",
    accentColors: ["#fff", "#dcb84c", "#b47b4f", "#dddbc7", "#E1DAC5"],
    content: false,
    contImg: "1.jpg",
  },
  {
    id: "2",
    name: ["光秀", "明智"],
    detailsJP: (
      <div>
        5.6oz ヘヴィウエイトコットン、アメリカの規格で <br />
        5.6オンス以上がヘヴィウエイトコットンとしている、
        <br />
        世界中で一番売れているTシャツ生地を使用。
      </div>
    ),
    detailsEng: (
      <div>
        5.6oz heavyweight cotton, American standard
        <br /> 5.6 ouncesor more is heavy weight cotton,
        <br /> Use the best-selling T-shirt fabric in the world.
      </div>
    ),
    price: 4000,
    brandImg: [
      "B1-min.png",
      "B2-min.png",
      "B3-min.png",
      "B4-min.png",
      "B5-min.png",
    ],
    detailsImg: "20(1).png",
    sideHighlight: "Akechi Mitsunhide",
    accentColors: ["#fff", "#8995ad", "#5aa1b5", "#b4d2cd", "#b4d3ce"],
    content: false,
    contImg: "2.jpg",
  },
  {
    id: "3",
    name: ["裾露草", "紋様", "地葡萄", "薄紅"],
    detailsJP: (
      <div>
        生地は生地織物、機織機の産地として有名な静岡県西部、 <br />
        つまり遠近国で生産された麻(リネン)を使用。パンツ、
        <br />
        ジャケットにも使用可能な糸番手を使い、力のかかる部分には <br />
        貫抜、袖下から脇を折り伏せ縫いで補強し、強度は作業着並。
      </div>
    ),
    detailsEng: (
      <div>
        Used the Linen produced in western Shizuoka perfecture, that is
        <br />
        , in Totominokuni, famous as the origin of fabrics and weaving
        <br />
        machines. The part where force is applied reinforces the fabric,
        <br />
        and the strength is similar to work clothes.
      </div>
    ),
    // price: 4500,
    brandImg: "6.jpg",
    detailsImg: "crop3.jpg",
    sideHighlight: "Usubenijibudoumonyoususotsukusa",
    accentColors: ["#fff", "#a3769a", "#ce9493", "#c9c1d0", "#dbd0de"],
    soon: true,
    content: true,
    contImg: "3.jpg",
  },
  {
    id: "4",
    name: ["裾露草", "撫子", "緑地"],
    detailsJP: (
      <div>
        生地は生地織物、機織機の産地として有名な静岡県西部、 <br />
        つまり遠近国で生産された麻(リネン)を使用。パンツ、
        <br />
        ジャケットにも使用可能な糸番手を使い、力のかかる部分には <br />
        貫抜、袖下から脇を折り伏せ縫いで補強し、強度は作業着並。
      </div>
    ),
    detailsEng: (
      <div>
        Used the Linen produced in western Shizuoka perfecture, that is
        <br />
        , in Totominokuni, famous as the origin of fabrics and weaving
        <br />
        machines. The part where force is applied reinforces the fabric,
        <br />
        and the strength is similar to work clothes.
      </div>
    ),
    // price: 5500,
    brandImg: "5.jpg",
    detailsImg: "crop4.jpg",
    sideHighlight: "Midorijinadeshikosusotsuyukusa",
    accentColors: ["#fff", "#8da81f", "#77996A", "#c8ccbf", "#c8ccbf"],
    soon: true,
    content: true,
    contImg: "4.jpg",
  },
];

export default () => {
  const classes = useStyles();

  const [IsSent, setIsSent] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const [name, setName] = React.useState("");
  const [telephone, setTelephone] = React.useState();
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(name, email, telephone, message);
    const { data } = await axios.post(
      BASE_URL + "/contact-email/",
      { name, tel: telephone, email, message },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log(data);
    if (data) {
      setIsSent(true);
    } else {
      toast.error(`email not sent. please try again`, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Cart />
      <Navbar />
      <section className="landing-img" id="0" />
      <section>
        {items.map((item, itemIndex) => (
          <BrandRow {...item} index={itemIndex + 1} key={itemIndex} />
        ))}
      </section>
      <section id="concept" style={{ position: "relative" }}>
        <div className="heading">
          <h1
            className="text-center"
            style={{
              fontFamily: "Noto Serif JP",
              fontSize: "40px",
              lineHeight: "3.8rem",
            }}
          >
            コンセプト
          </h1>
          <h3
            className="text-center"
            style={{ fontFamily: '"Oswald", sans-serif' }}
          >
            Concept
          </h3>
        </div>
        <div className="d-flex">
          <div className="mobimg">
            <img
              src={require("../../Images/mainlogo.png")}
              // style={{ width: "250px" }}
              alt="cloth-1"
            />
          </div>
          <div className="con lg-view">
            <h2>
              戦国武将の肖像をつかったオモシロTシャツと、
              <br />
              和紋様のアロハシャツ。
              <br />
              日本の伝統をアレンジしたオツな商品です。
              <br />
              「オッツ」とお呼びください。
            </h2>
            <br />
            <h3>
              Omoshiro T-shirt with a portrait of Sengoku warlord, <br />
              and Japanese pattern Aloha shirt.
              <br />
              It is a hot item that arranges Japanese tradition.
              <br /> Please call me "Ots".
            </h3>
            <br />
          </div>
          <div className="con sm-view">
            <h2>
              戦国武将の肖像をつかったオモシロTシャツと、
              <br />
              和紋様のアロハシャツ。
              <br />
              日本の伝統をアレンジしたオツな商品です。
              <br />
              「オッツ」とお呼びください。
            </h2>
            <br />
            <h3>
              Omoshiro T-shirt with a portrait of Sengoku warlord, <br />
              and Japanese pattern Aloha shirt.
              <br />
              It is a hot item that arranges Japanese tradition.
              <br /> Please call me "Ots".
            </h3>
            <br />
          </div>
        </div>
        <div className="mg-adj">
          <h3
            className="text-center"
            style={{ fontSize: "1.5em", fontFamily: "Noto Serif JP" }}
          >
            樋口製作所
          </h3>
          <h5
            className="text-center"
            style={{ fontFamily: '"Oswald", sans-serif' }}
          >
            Higuchi Seisakusho
          </h5>
        </div>
      </section>
      <section id="contact" style={{ marginTop: "0.5rem" }}>
        <div className="heading">
          <h1
            className="text-center"
            style={{
              fontFamily: "Noto Serif JP",
              fontSize: "40px",
              lineHeight: "3.8rem",
            }}
          >
            お問合わせ
          </h1>
          <h3
            className="text-center"
            style={{ fontFamily: '"Oswald", sans-serif' }}
          >
            Contact
          </h3>
          <br />

          <div className="con1">
            <h2 className="text-center">
              商品に関するお問い合わせは以下より受け付けております。
            </h2>

            <h3
              className="text-center"
              style={{ fontFamily: '"Oswald", sans-serif' }}
            >
              please contact from below.
            </h3>
          </div>
        </div>
        <div>
          <button
            className="contact-btn"
            style={{ fontFamily: '"Oswald", sans-serif' }}
            onClick={handleClickOpen}
          >
            Contact
          </button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth="true"
            classes={{ paperFullWidth: classes.MuiDialogPaperWidthSm }}
          >
            {!IsSent ? (
              <Form
                style={{ maxWidth: "100%", backgroundColor: "white" }}
                onSubmit={handleSubmit}
              >
                <DialogTitle id="form-dialog-title">
                  <h2 style={{ textAlign: "center", color: "grey" }}>
                    お問い合わせ
                  </h2>
                  <h4 style={{ textAlign: "center", color: "grey" }}>
                    Contact
                  </h4>
                  <h6 style={{ textAlign: "right", color: "grey" }}>
                    <span style={{ color: "red" }}>*</span>
                    は必須項目です。電話番号とメールアドレスはいずれか必須です。
                  </h6>
                </DialogTitle>
                <DialogContent>
                  <div style={{ marginBottom: "15px", marginTop: "15px" }}>
                    <TextField
                      required
                      id="outlined-required"
                      label="名前"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      // defaultValue="Hello World"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                  <div style={{ marginBottom: "15px", marginTop: "15px" }}>
                    <TextField
                      required
                      type="number"
                      id="outlined-required"
                      label="電話番号"
                      value={telephone}
                      onChange={(e) => setTelephone(e.target.value)}
                      // defaultValue="Hello World"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                  <div style={{ marginBottom: "15px", marginTop: "15px" }}>
                    <TextField
                      required
                      id="outlined-required"
                      label="メールアドレス"
                      value={email}
                      // validations={[required]}
                      type="email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      // defaultValue="Hello World"
                      variant="outlined"
                      max-width="100%"
                      fullWidth
                      size="medium"
                    />
                  </div>
                  <div style={{ marginBottom: "15px", marginTop: "15px" }}>
                    <TextField
                      required
                      id="outlined-required"
                      label="本文"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      // defaultValue="Hello World"
                      variant="outlined"
                      fullWidth
                      size="medium"
                      multiline={true}
                      rows={8}
                    />
                  </div>
                </DialogContent>
                <DialogActions
                // classes={{ paperFullWidth: classes.MuiDialogPaperWidthSm }}
                >
                  <Button
                    variant="outlined"
                    size="large"
                    style={{ margin: "0 auto", color: "gray" }}
                    type="submit"
                  >
                    送信
                  </Button>
                </DialogActions>
              </Form>
            ) : (
              <div>
                <DialogTitle id="form-dialog-title">
                  <h2 style={{ textAlign: "center", color: "grey" }}>
                    お問い合わせ
                  </h2>
                  <h4 style={{ textAlign: "center", color: "grey" }}>
                    Contact
                  </h4>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText style={{ height: "30vh" }}>
                    <h1
                      style={{
                        // marginLeft: "15vw",
                        // marginTop: "20vh",
                        textAlign: "center",
                        color: "black",
                        fontWeight: "lighter",
                        verticalAlign: "middle",
                      }}
                    >
                      お問い合わせは正常に送信されました。
                    </h1>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    variant="outlined"
                    size="large"
                    style={{ margin: "0 auto", color: "gray" }}
                    type="submit"
                  >
                    送信
                  </Button>
                </DialogActions>
              </div>
            )}
          </Dialog>
        </div>
        <br />
      </section>
      <img
        src={require("../../Images/crop7.jpg")}
        style={{ objectFit: "contain", width: "100%" }}
        className="footerimg"
        alt="cloth-1"
      />
      <ToastContainer style={{ width: "370px" }} />
    </>
  );
};
