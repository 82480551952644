import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import { AppProvider } from "./Component/Context";
import Routes from "./Pages/Routes";

function App() {
  return (
    <AppProvider>
      <ParallaxProvider>
        <Router>
          <Routes />
        </Router>
      </ParallaxProvider>
    </AppProvider>
  );
}

export default App;
