import axios from "axios";
import { BASE_URL } from "../config";

const productSearch = (product) => {
  return axios.get(
    `${BASE_URL}/products/search?searchString=${product.name[1]} ${product.name[0]}`
  );
};

const createOrder = async (
  paypal_id,
  name,
  email,
  phone,
  address,
  city,
  state,
  country,
  postCode,
  total,
  products
) => {
  // console.log(products);
  const array = await products.map(async (product) => {
    const res = await productSearch(product);
    const product_id = res.data.data[0]._id;
    const obj = {
      item: product_id,
      quantity: product.qty,
      size: product.size,
    };
    const passdata = {
      paypalTransactionId: paypal_id,
      customerInfo: {
        name: name,
        email: email,
        phone: phone,
        address: address,
        city: city,
        state: state,
        country: country,
        postCode: postCode,
      },
      billingInfo: name,
      product: obj,
      baseOrderPrice: total,
      orderedAt: new Date().toISOString(),
      shipmentStatus: "RECEIVED",
    };

    console.log(passdata);

    const { data } = await axios.post(BASE_URL + "/otz-orders/", passdata, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data;
  });

  return;
};

export default {
  createOrder,
};
