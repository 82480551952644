import React, { useState, useEffect } from "react";
import useOutsideClick from "../hooks/useOutsideClick";
import { TweenMax } from "gsap";

export default function CustomDropdown({ options = [], callback = () => {} }) {
  const [selected, setSelected] = useState(options[0] || "");
  const [open, setOpen] = useState(false);
  const dref = React.createRef();
  const displayRef = React.createRef();
  const closeList = () => {
    setOpen(false);
  };
  useEffect(() => {
    TweenMax.to(displayRef.current, {
      duration: 0.03,
      opacity: 0,
      y: -20,
      onComplete: () => {
        TweenMax.fromTo(
          displayRef.current,
          { opacity: 0, y: 20 },
          { duration: 0.2, opacity: 1, y: 0 }
        );
      },
    });
  }, [selected]);
  useOutsideClick(closeList, dref);
  return (
    <div className="c-dd" ref={dref} 
    // style={{position:"relative"}}
    >
      <div
        onClick={() => {
          setOpen(!open);
        }}
        className="c-dd-display"
        // style={{
        //   display:"grid",
        //   placeItems:"center",
        //   width:"6rem",
        //   height:"6rem",
        //   backgroundColor:"#fff",
        //   boxShadow:" 0 0 0 3px #000",
        //   cursor:"pointer",
        //   boxSizing:"border-box",
        //   paddingTop:"10px"
        // }}
      >
        <span ref={displayRef} 
        className="c-dd-span"
        // style={{
        //   fontFamily:"Oswald, sans-serif",
        //   fontSize:"4rem",
        //   lineHeight:"1",
        // }}
        >
          {selected}
        </span>
      </div>
      {open && (
        <ul 
        className="c-dd-ul"
        // style={{
        //   position:"absolute",
        //   top:"100%",
        //   backgroundColor:"#fff",
        //   minWidth:"100%",
        //   boxShadow:"0 0 0 1px rgba($color: #000000, $alpha: 0.5)",
        //   zIndex:"10",
        // }}
        >
          {options.map((o) => (
            <li
              // style={{padding:"5px 1rem", cursor:"pointer"}}
              className="c-dd-li"
              onClick={() => {
                closeList();
                setSelected(o);
                callback(o);
              }}
            >
              {o}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
