import React, { useContext, useState } from "react";

const AppContext = React.createContext(null);
export const AppProvider = ({ children }) => {
  const appState = useState({
    cart: {
      visible: false,
      items: [],
      menu: false,
    },
  });
  return <AppContext.Provider value={appState}>{children}</AppContext.Provider>;
};
export const useAppContext = () => {
  const [state, setState] = useContext(AppContext);
  return {
    state,
    toggleShowCart: (toggle) =>
      setState((st) => ({ ...st, cart: { ...st.cart, visible: toggle } })),
    toggleShowMenu: (toggle) =>
      setState((st) => ({ ...st, cart: { ...st.cart, menu: toggle } })),
    addItem: (item) => {
      console.log(item);
      const existItemWithSameSize = state.cart.items.find(
        (x) => x.id === item.id && x.size === item.size
      );
      if (existItemWithSameSize) {
        setState((st) => ({
          ...st,
          cart: {
            ...st.cart,
            items: state.cart.items.map((x) =>
              x.id === item.id && x.size === item.size
                ? { ...item, qty: item.qty + x.qty }
                : x
            ),
          },
        }));
      } else {
        setState((st) => ({
          ...st,
          cart: { ...st.cart, items: [...st.cart.items, item] },
        }));
      }
    },
    changeItem: (item) => {
      console.log(item);
      const existItemWithSameSize = state.cart.items.find(
        (x) => x.id === item.id && x.size === item.size
      );
      if (existItemWithSameSize) {
        setState((st) => ({
          ...st,
          cart: {
            ...st.cart,
            items: state.cart.items.map((x) =>
              x.id === item.id && x.size === item.size ? item : x
            ),
          },
        }));
      } else {
        setState((st) => ({
          ...st,
          cart: { ...st.cart, items: [...st.cart.items, item] },
        }));
      }
    },
    removeItem: (id) =>
      setState((st) => ({
        itemId: parseInt(Math.random() * 10000),
        ...st,
        cart: {
          ...st.cart,
          items: st.cart.items.filter((it) => it.itemId !== id),
        },
      })),
    emptyCart: () =>
      setState((st) => ({
        ...st,
        cart: { ...st.cart, items: [] },
      })),
  };
};
