import React, { useState, useRef, useEffect } from "react";
import { useAppContext } from "./Context";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { isEmail } from "validator";
import Typography from "@material-ui/core/Typography";
import * as BsIcons from "react-icons/bs";
import {
  IoMdClose,
  IoIosTrash,
  IoIosArrowDropleftCircle,
} from "react-icons/io";
import { AiFillInfoCircle } from "react-icons/ai";

import PayWithPayPal from "./PayWithPayPal";
import priceObj from "../helper/priceObject";
import DropdownCart from "./DropdownCart";

import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { ToastContainer, toast } from "react-toastify";
import "react-phone-number-input/style.css";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";

const useStyles = makeStyles((theme) => ({
  customWidth: {
    maxWidth: 20,
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert" style={{ color: "red" }}>
        This field is required!
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert" style={{ color: "red" }}>
        This is not a valid email.
      </div>
    );
  }
};

const validCode = (value) => {
  if (value.length > 6) {
    return (
      <div className="alert alert-danger" role="alert" style={{ color: "red" }}>
        This is not a valid pincode.
      </div>
    );
  }
};

const validPhone = (value) => {
  if (value.length > 10 && value.length < 10) {
    return (
      <div className="alert alert-danger" role="alert" style={{ color: "red" }}>
        This is not a valid Phone number.
      </div>
    );
  }
};

export default function Cart(props) {
  const {
    state: {
      cart: { visible, items },
    },
    changeItem,
    toggleShowCart,
    removeItem,
  } = useAppContext();
  const form = useRef();
  const [check, setCheck] = useState(true);
  // const [paidFor, setPaidFor] = useState(false);
  const [pay, setPay] = useState(false);

  const [warn, setWarn] = useState(false);
  const [region, setRegion] = useState("");
  const [out, setOut] = useState(false);
  const [ts, setTs] = useState();
  const [bill, setBill] = useState();
  const [shippingFees, setShippingFees] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [postCode, setPostCode] = useState("");
  const [successful, setSuccessful] = useState(false);
  // const [message, setMessage] = useState("");

  const [checked, setChecked] = useState(false);

  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePostCode = (e) => {
    const name = e.target.value;
    setPostCode(name);
  };

  const onChangeState = (e) => {
    const name = e.target.value;
    setState(name);
  };

  const onChangeCity = (e) => {
    const name = e.target.value;
    setCity(name);
  };

  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
  };

  const onChangePhone = (e) => {
    // const name = e.target.value;
    setPhone(e);
  };

  const onChangeAddress = (e) => {
    const addr = e.target.value;
    setAddress(addr);
  };

  const onChangeCountry = (e) => {
    const country = e;
    setCountry(country);
  };

  const onSetTrue = () => {
    setCheck(true);
    toast.success(`お買い上げありがとうございました`, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const changeRegion = (e) => {
    const region = e;
    setRegion(region);
    if (region === "asia") {
      setCountry("India");
    }
    if (region === "europe") {
      setCountry("Oceania");
    }
    if (region === "africa") {
      setCountry("South America");
    }
    // console.log(priceObj);
    // console.log(region);
    const reg = priceObj[region];
    // console.log(reg);
    var ts = 0;
    var bill = 0;
    var shippingFee = 0;
    items.forEach((item) => {
      var price = item.price * item.qty;
      ts += price;
      // console.log(sp);
      bill += price;
    });
    shippingFee = reg[1];
    ts = ts + shippingFee;
    if (region === "japan" && checked == true) {
      ts = ts + 600;
      shippingFee = shippingFee + 600;
    }
    setTs(ts);
    setBill(bill);
    setShippingFees(shippingFee);
    setOut(true);
  };

  useEffect(() => {
    var itemCount = 0;
    items.forEach((product) => {
      itemCount += product.qty;
    });
    console.log(itemCount);
    if (itemCount > 5) {
      setWarn(true);
    } else {
      setWarn(false);
    }
    changeRegion("japan");
    onChangeCountry("Japan");
    // if (checked == true) {
    //   setTs(ts + 600);
    //   setShippingFees(shippingFees + 600);
    // }
  }, [items]);

  console.log(items);

  // const isEnabled =
  //   email.length > 0 &&
  //   isEmail(email) &&
  //   name.length > 0 &&
  //   address.length > 0 &&
  //   country.length > 0 &&
  //   postCode.length > 0 &&
  //   state.length > 0 &&
  //   city.length > 0;

  const breakDown = () => {};

  const onClick = (e) => {
    e.preventDefault();

    // setMessage("");

    // form.current.validateAll();
    if (
      email.length > 0 &&
      isEmail(email) &&
      name.length > 0 &&
      address.length > 0 &&
      country.length > 0 &&
      postCode.length > 0 &&
      state.length > 0 &&
      city.length > 0
    ) {
      setPay(true);
      setSuccessful(true);
    }
  };

  const okinawa = (e) => {
    console.log(e.target.checked);
    setChecked(!checked);
    if (e.target.checked) {
      setShippingFees(shippingFees + 600);
      setTs(ts + 600);
    } else {
      setShippingFees(shippingFees - 600);
      setTs(ts - 600);
    }
  };

  return visible ? (
    check ? (
      <>
        <div onClick={() => toggleShowCart(false)} className="cart-back" />
        <div className="pageWrapper">
          <IoMdClose
            className="close-icon"
            style={{
              position: "absolute",
              fontSize: "2rem",
              right: "1.5rem",
              top: "1.5rem",
              cursor: "pointer",
            }}
            onClick={() => toggleShowCart(false)}
          />
          <h2
            id="ct"
            style={{
              margin: "1.5rem 2rem",
              fontFamily: "Oswald, sans-serif",
              fontSize: "2rem",
            }}
          >
            CART カート
          </h2>
          {/* <hr /> */}
          {/* </div> */}
          <div className="cart">
            <div className="cart-wrapper">
              <div className="formWrap">
                <label htmlFor="region" className="regionTitle">
                  お住まいの地域を選択してください。
                </label>
                <div className="select">
                  <select
                    name="region"
                    id="region"
                    value={region}
                    onChange={(e) => changeRegion(e.target.value)}
                  >
                    <option value="japan">Japan</option>
                    <option value="asia">Asia</option>
                    <option value="europe">
                      Oceania, North America, Central America, Middle East
                    </option>
                    <option value="africa">South America, Africa</option>
                  </select>
                </div>
              </div>
              {warn && (
                <div
                  style={{
                    background: "red",
                    width: "70%",
                    marginLeft: "45px",
                    padding: "5%",
                    borderRadius: "15px",
                    fontFamily: '"Oswald", sans-serif',
                  }}
                >
                  一度の注文で5点までに制限しております。会計を完了させるために超過分の商品を減らして続行してください。宜しくお願い致します。
                </div>
              )}
              {items.map((c) => (
                <div className="listPhotos" key={c.id}>
                  <div className="imageWrapper">
                    <div className="c-size">{c.size}</div>
                    <img
                      style={{
                        height: "7rem",
                        width: "7rem",
                        borderRadius: "3px",
                      }}
                      src={require(`../Images/${c.itemImg[0]}`)}
                      alt="shirts"
                    />
                  </div>
                  <div className="detailsWrapper">
                    <p className="detailName">{c.name}</p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginLeft: "35px",
                      }}
                    >
                      <div className="price">¥{c.price.toLocaleString()}</div>
                      <div className="qtyWrapper">
                        <DropdownCart
                          style={{
                            backgroundColor: "black",
                            color: "white",
                            borderRadius: "10px",
                            padding: "4px",
                          }}
                          value={c.qty}
                          callback={(qty) => {
                            // console.log(items);
                            changeItem({
                              ...c,
                              qty: qty,
                            });
                          }}
                          options={new Array(5).fill("").map((_, i) => i + 1)}
                        />
                      </div>
                      <div className="deleteWrapper">
                        <BsIcons.BsTrash
                          size="20px"
                          className="del-icon"
                          onClick={() => {
                            removeItem(c.itemId);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {region === "japan" && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={okinawa}
                      name="checked"
                    />
                  }
                  label={
                    <div
                      style={{
                        marginTop: "15px",
                        width: "250px",
                        marginLeft: "25px",
                      }}
                    >
                      <h3 className="cart-rule1">
                        沖縄にお住まいの方は送料が追加で¥600かかります。
                      </h3>
                      <h3 className="cart-rule1">
                        該当する場合は、上記チェックマークを入れて会計にお進みください。
                      </h3>
                    </div>
                  }
                />
              )}

              <hr
                style={{
                  width: "300px",
                  marginTop: "20px",
                  marginBottom: "27px",
                }}
              />
              <div
                style={{
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <h3
                  className="cart-rule"
                  style={{ letterSpacing: "2px", width: "fit-content" }}
                >
                  Shipping Fee 送料 :
                </h3>
                <h3 className="cart-rule" style={{ letterSpacing: "2px" }}>
                  ¥{shippingFees}
                </h3>
              </div>
            </div>
            <hr style={{ width: "150px", marginTop: "30px" }} />
            <div className="cart-bottom">
              <div className="c-t">
                <div style={{ marginTop: "5px", marginRight: "12px" }}>
                  Total 合計 :{" "}
                </div>
                <span>
                  {region
                    ? `¥ ${ts.toLocaleString()}`
                    : `¥ ${items.reduce(
                        (pr, cu) => pr + cu.price * cu.qty,
                        0
                      )}`}
                  <ClickAwayListener onClickAway={handleTooltipClose}>
                    <HtmlTooltip
                      style={{ marginLeft: "-13px" }}
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={handleTooltipClose}
                      open={open}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      placement="top-end"
                      // classes={{ tooltip: classes.customWidth }}
                      title={
                        <React.Fragment>
                          <Typography color="inherit">
                            Billing Details
                          </Typography>
                          <p>Purchased Items: ¥{bill}</p>
                          <p>Shipping Fees: ¥{shippingFees}</p>
                        </React.Fragment>
                      }
                    >
                      <Button onClick={handleTooltipOpen}>
                        <AiFillInfoCircle />
                      </Button>
                    </HtmlTooltip>
                  </ClickAwayListener>
                </span>
              </div>
              {!warn && out && (
                <div
                  className="c-f"
                  onClick={() => {
                    if (items.reduce((pr, cu) => pr + cu.price * cu.qty, 0) > 0)
                      setCheck(false);
                  }}
                >
                  CHECKOUT 会計
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    ) : (
      <div>
        <div onClick={() => toggleShowCart(false)} className="cart-back"></div>
        <div className="pageWrapper" style={{ width: "390px" }}>
          <h2
            style={{
              margin: "1.5rem 2rem",
              fontFamily: "Oswald, sans-serif",
              fontSize: "2rem",
            }}
          >
            CHECKOUT 会計
          </h2>
          <IoIosArrowDropleftCircle
            className="close-icon"
            style={{
              position: "absolute",
              fontSize: "2rem",
              right: "1.5rem",
              top: "1.5rem",
              cursor: "pointer",
            }}
            onClick={() => setCheck(true)}
          />
          {/* <hr style={{width:"100%"}} /> */}
          <div className="cart">
            <div style={{ textAlign: "center" }}>
              {!successful && (
                <Form onSubmit={onClick} ref={form}>
                  <div className="formWrapper">
                    <div
                      style={{
                        color: "#494949",
                        fontFamily: '"Oswald", sans-serif',
                        fontSize: "1.65rem",
                      }}
                    >
                      Enter Your Details{" "}
                    </div>
                    <div
                      style={{
                        fontWeight: "lighter",
                        marginLeft: "8px",
                        fontSize: "1.3rem",
                        marginBottom: "12px",
                      }}
                    >
                      お客様情報をご入力ください。
                    </div>

                    <div className="form-group">
                      <div className="formsubGroup">
                        <label className="titleWrapper" for="name">
                          Name
                          <span
                            style={{ fontWeight: "lighter", marginLeft: "8px" }}
                          >
                            名前
                          </span>
                        </label>
                      </div>
                      <Input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="名前"
                        value={name}
                        onChange={onChangeName}
                        validations={[required]}
                      />
                    </div>
                    <div className="form-group">
                      <div className="formsubGroup">
                        <label className="titleWrapper" for="email">
                          Email{" "}
                          <span
                            style={{ fontWeight: "lighter", marginLeft: "8px" }}
                          >
                            メールアドレス
                          </span>
                        </label>
                      </div>
                      <Input
                        type="text"
                        name="email"
                        id="email"
                        placeholder="メールアドレス"
                        value={email}
                        onChange={onChangeEmail}
                        validations={[required, validEmail]}
                      />
                    </div>

                    <div className="form-group">
                      <div className="formsubGroup">
                        <label for="#phone" className="titleWrapper">
                          Phone Number{" "}
                          <span
                            style={{ fontWeight: "lighter", marginLeft: "8px" }}
                          >
                            電話番号
                          </span>
                        </label>
                      </div>
                      <PhoneInput
                        // name="nknjnnjn"
                        // id="yeyeyyeyeyye"
                        // className="phone"
                        // autoComplete="off"
                        defaultCountry="JP"
                        style={{
                          border: "none",
                        }}
                        placeholder="電話番号"
                        value={phone}
                        onChange={(e) => setPhone(e)}
                        // validations={[required]}
                        error={
                          phone
                            ? isValidPhoneNumber(phone)
                              ? undefined
                              : "Invalid phone number"
                            : "Phone number required"
                        }
                      />
                    </div>
                    <div className="form-group">
                      <div className="formsubGroup">
                        <label for="address" className="titleWrapper">
                          Address{" "}
                          <span
                            style={{ fontWeight: "lighter", marginLeft: "8px" }}
                          >
                            住所
                          </span>
                        </label>
                      </div>
                      <Input
                        type="text"
                        name="address"
                        id="address"
                        placeholder="住所"
                        value={address}
                        onChange={onChangeAddress}
                        validations={[required]}
                      />
                    </div>
                    <div className="form-group">
                      <div className="formsubGroup">
                        <label for="city" className="titleWrapper">
                          City{" "}
                          <span
                            style={{ fontWeight: "lighter", marginLeft: "8px" }}
                          >
                            市区町村
                          </span>
                        </label>
                      </div>
                      <Input
                        type="text"
                        name="city"
                        id="city"
                        placeholder="市区町村"
                        value={city}
                        onChange={onChangeCity}
                        validations={[required]}
                      />
                    </div>

                    <div className="form-group">
                      <div className="formsubGroup">
                        <label for="state" className="titleWrapper">
                          State{" "}
                          <span
                            style={{ fontWeight: "lighter", marginLeft: "8px" }}
                          >
                            都道府県
                          </span>
                        </label>
                      </div>
                      <Input
                        type="text"
                        name="state"
                        placeholder="都道府県"
                        id="state"
                        value={state}
                        onChange={onChangeState}
                        validations={[required]}
                      />
                    </div>

                    <div className="form-group">
                      <div className="formsubGroup">
                        <label for="postcode" className="titleWrapper">
                          Post Code{" "}
                          <span
                            style={{ fontWeight: "lighter", marginLeft: "8px" }}
                          >
                            郵便番号
                          </span>
                        </label>
                      </div>
                      <Input
                        type="text"
                        name="postcode"
                        id="postcode"
                        placeholder="郵便番号"
                        value={postCode}
                        onChange={onChangePostCode}
                        validations={[required]}
                      />
                    </div>

                    <label
                      htmlFor="retere"
                      style={{
                        fontWeight: "bold",
                        color: "#2c3e50",
                        fontSize: "1.2rem",
                      }}
                    >
                      Country{" "}
                      <span
                        style={{ fontWeight: "lighter", marginLeft: "8px" }}
                      >
                        居住国
                      </span>
                    </label>

                    <div
                      style={{
                        justifyContent: "center",
                        marginLeft: "30px",
                        marginBottom: "20px",
                      }}
                    >
                      {region === "japan" && (
                        <div
                          className="select"
                          style={{ alignItems: "center", textAlign: "center" }}
                        >
                          <select
                            name="retere"
                            id="retere"
                            value={country}
                            onChange={(e) => onChangeCountry(e.target.value)}
                            validations={[required]}
                          >
                            <option value="Japan">Japan</option>
                          </select>
                        </div>
                      )}
                      {region === "asia" && (
                        <div className="select">
                          <select
                            name="retere"
                            id="retere"
                            value={country}
                            onChange={(e) => onChangeCountry(e.target.value)}
                            validations={[required]}
                          >
                            <option value="India">India</option>
                            <option value="Sri Lanka">Sri Lanka</option>
                            <option value="Bhutan">Bhutan</option>
                            <option value="Nepal">Nepal</option>
                          </select>
                        </div>
                      )}
                      {region === "europe" && (
                        <div className="select">
                          <select
                            name="country"
                            id="country"
                            value={country}
                            onChange={(e) => onChangeCountry(e.target.value)}
                            validations={[required]}
                          >
                            <option value="Oceania">Oceania</option>
                            <option value="North America">North America</option>
                            <option value="Central America">
                              Central America
                            </option>
                            <option value="Middle East">Middle East</option>
                          </select>
                        </div>
                      )}
                      {region === "africa" && (
                        <div className="select">
                          <select
                            name="country"
                            id="country"
                            value={country}
                            onChange={(e) => onChangeCountry(e.target.value)}
                            validations={[required]}
                          >
                            <option value="South America">South America</option>
                            <option value="Africa">Africa</option>
                          </select>
                        </div>
                      )}
                    </div>

                    <label
                      htmlFor="country"
                      style={{
                        fontSize: "12px",
                        marginLeft: "17px",
                        marginTop: "7px",
                        marginBottom: "7px",
                      }}
                    >
                      ※居住国はお住まいのエリアを参照して表示されています。送料の金額を決定するためご入力ください。
                    </label>

                    <div className="cart-bottom">
                      <div className="c-t" style={{ marginTop: "9px" }}>
                        <div
                          style={{
                            marginTop: "5px",
                            marginLeft: "37px",
                            marginRight: "7px",
                            fontSize: "1.5rem",
                          }}
                        >
                          Total 合計 :
                        </div>
                        <span>
                          ¥ {ts.toLocaleString()}
                          <ClickAwayListener onClickAway={handleTooltipClose}>
                            <HtmlTooltip
                              PopperProps={{
                                disablePortal: true,
                              }}
                              onClose={handleTooltipClose}
                              open={open}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              placement="top-end"
                              // classes={{ tooltip: classes.customWidth }}
                              title={
                                <React.Fragment>
                                  <Typography color="inherit">
                                    Billing Details
                                  </Typography>
                                  <p>Purchased Items: ¥{bill}</p>
                                  <p>Shipping Fees: ¥{shippingFees}</p>
                                </React.Fragment>
                              }
                            >
                              <Button
                                onClick={handleTooltipOpen}
                                style={{ marginLeft: "-13px" }}
                              >
                                <AiFillInfoCircle />
                              </Button>
                            </HtmlTooltip>
                          </ClickAwayListener>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="form-group" style={{ height: "9.5rem" }}>
                    <button
                      className=" sub"
                      style={{
                        fontFamily: '"Oswald", sans-serif',
                        marginTop: "-15px",
                      }}
                      // disabled={!isEnabled}
                    >
                      Submit
                    </button>
                  </div>
                  {/* </div> */}
                </Form>
              )}

              {successful ? (
                <div
                  style={{
                    background: "green",
                    width: "70%",
                    marginLeft: "45px",
                    padding: "5%",
                    borderRadius: "15px",
                    fontFamily: '"Oswald", sans-serif',
                    marginBottom: "7px",
                    marginTop: "25px",
                  }}
                >
                  お客様の情報は正常に更新されました。
                  お支払い画面にお進みください。
                  <br />
                  Your details have been stored successfully. Please proceed to
                  the payment screen by clicking on the button below.
                </div>
              ) : (
                ""
              )}

              {pay ? (
                <div
                  style={{
                    width: "250px",
                    margin: "auto",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <hr
                    style={{
                      marginTop: "7px",
                      marginBottom: "7px",
                      width: "150px",
                    }}
                  />
                  <span
                    style={{
                      fontSize: "1.8rem",
                      fontFamily: "Oswald, sans-serif",
                      marginTop: "5px",
                      marginBottom: "7px",
                      color: "grey",
                    }}
                  >
                    {" "}
                    Order Details{" "}
                  </span>
                  <span
                    style={{
                      fontSize: "1.5rem",
                      fontFamily: "Oswald, sans-serif",
                      marginTop: "5px",
                      marginBottom: "5px",
                      color: "grey",
                    }}
                  >
                    {" "}
                    Purchased Items : ¥ {bill}{" "}
                  </span>
                  <span
                    style={{
                      fontSize: "1.5rem",
                      fontFamily: "Oswald, sans-serif",
                      marginTop: "5px",
                      marginBottom: "5px",
                      color: "grey",
                    }}
                  >
                    {" "}
                    Shipping Fees : ¥ {shippingFees}{" "}
                  </span>
                  <span
                    style={{
                      fontSize: "1.5rem",
                      fontFamily: "Oswald, sans-serif",
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    {" "}
                    Total 合計 : ¥ {ts.toLocaleString()}
                  </span>
                  <hr
                    style={{
                      marginTop: "12px",
                      marginBottom: "15px",
                      width: "150px",
                    }}
                  />
                  <PayWithPayPal
                    ts={ts}
                    setCheck={onSetTrue}
                    name={name}
                    email={email}
                    phone={phone}
                    address={address}
                    city={city}
                    state={state}
                    country={country}
                    postCode={postCode}
                  />
                </div>
              ) : null}
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
    )
  ) : null;
}
