import React, { useEffect } from "react";
// import { TweenMax } from "gsap";
import { HashLink as Link } from "react-router-hash-link";

const menuOptions = [
  { jp: "お問い合わせ", text: "Contact", link: "#contact" },
  { jp: "コンセプト", text: "Concept", link: "#concept" },
  {
    jp: "お問い合わせ",
    text: "Items",
    options: [
      {
        jp: "緑地撫子裾露草",
        text: "Midorijinadeshikosusotsuyukusa",
        link: "#4",
      },
      {
        jp: "薄紅地葡萄紋様裾露草",
        text: "Usubenijibudoumonyoususotsuyukusa",
        link: "#3",
      },
      { jp: "明智光秀", text: "Akechi Mitsuhide", link: "#2" },
      { jp: "織田信長", text: "Oda Nobunaga", link: "#1" },
    ],
  },
  { jp: "商品紹介", text: "Items", link: "#0" },
];
export default function MenuOverlay({ onClose }) {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <div
      className="menu-overlay"
      onClick={() => {
        onClose();
      }}
    >
      <ul className="a">
        {menuOptions.map((m, i) =>
          m.options ? (
            <ul className="b">
              {m.options.map((op, index) => (
                <Link to={op.link}>
                  <li style={{ animationDelay: `${i * 50}ms` }}>
                    <p style={{ fontFamily: "Noto Serif JP", fontWeight: 700 }}>
                      {op.jp}
                    </p>
                    <span style={{ fontFamily: "Oswald", fontWeight: 500 }}>
                      {op.text}
                    </span>
                  </li>
                </Link>
              ))}
            </ul>
          ) : (
            <Link to={m.link}>
              <li style={{ animationDelay: `${i * 50}ms` }}>
                <p style={{ fontFamily: "Noto Serif JP", fontWeight: 700 }}>
                  {m.jp}
                </p>
                <span style={{ fontFamily: "Oswald", fontWeight: 500 }}>
                  {m.text}
                </span>
              </li>
            </Link>
          )
        )}
      </ul>
    </div>
  );
}
